import React, { useContext } from 'react'

export function loadEnvironmentVariables(): EnvironmentContextValues {
  return {
    googlePlaceAPIKey: process.env.GOOGLE_PLACE_API_KEY || '',
    grafanaFaroCollectorURL: process.env.GRAFANA_FARO_COLLECTOR_URL || '',
    rubiconBundleName: process.env.RUBICON_BUNDLE_NAME || '',
    adsLogging: process.env.ADS_LOGGING === 'true',
    adBundle: process.env.AD_BUNDLE || ''
  }
}

export type EnvironmentContextValues = {
  googlePlaceAPIKey: string
  grafanaFaroCollectorURL: string
  rubiconBundleName: string
  adsLogging: boolean
  adBundle: string
}

export const EnvironmentContext = React.createContext<EnvironmentContextValues>({
  googlePlaceAPIKey: '',
  grafanaFaroCollectorURL: '',
  rubiconBundleName: '',
  adsLogging: true,
  adBundle: ''
})

export const useEnvironmentContext = () => {
  return useContext(EnvironmentContext)
}

const EnvironmentProvider = ({
  children,
  environmentVariables
}: {
  children: React.ReactNode
  environmentVariables: EnvironmentContextValues
}) => {
  const value: EnvironmentContextValues = {
    googlePlaceAPIKey: environmentVariables.googlePlaceAPIKey,
    grafanaFaroCollectorURL: environmentVariables.grafanaFaroCollectorURL,
    rubiconBundleName: environmentVariables.rubiconBundleName,
    adsLogging: environmentVariables.adsLogging,
    adBundle: environmentVariables.adBundle
  }

  return <EnvironmentContext.Provider value={value}>{children}</EnvironmentContext.Provider>
}

export default EnvironmentProvider
