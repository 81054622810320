import { useEffect } from 'react'

import { Logger } from 'winston'

type AirshipWebNotficationsProps = {
  region: string
  logger: Logger | Console
}

const AirshipWebNotifications = ({ region, logger }: AirshipWebNotficationsProps): null => {
  const MAX_RETRIES = 5
  let retryCount = 0

  const checkSdk = () => {
    const SAFARI_BROWSER = navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') < 0
    const tag = `frankly-breaking-${region}`

    if (region === 'default') return

    if (window.UA) {
      window.UA.then(sdk => {
        if (sdk.permission === 'default') {
          if (SAFARI_BROWSER) {
            const clickHandler = () => {
              sdk.register()
              document.removeEventListener('click', clickHandler)
            }
            document.addEventListener('click', clickHandler)
            return
          }
          sdk.register()
        }

        if (sdk.channel && !sdk.channel.tags.has(tag)) {
          sdk.getChannel().then((channel: { tags: { add: (tag: string) => void } }) => {
            channel.tags.add(tag)
          })
        }
      })
    } else if (retryCount < MAX_RETRIES) {
      retryCount++
      setTimeout(checkSdk, 300)
    } else {
      logger.error('Max retries reached while waiting for window.UA to become available')
    }
  }

  const registerServiceWorker = () => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', function () {
        navigator.serviceWorker.register('/push-worker.js').then(
          function (registration) {
            logger.info('UA ServiceWorker registration successful with scope: ', registration.scope)
          },
          function (err) {
            logger.error('UA ServiceWorker registration failed: ', err)
          }
        )
      })
    }
  }

  useEffect(() => {
    try {
      registerServiceWorker()
      checkSdk()
    } catch (error) {
      logger.error(error)
    }
  }, [])

  return null
}

export default AirshipWebNotifications
