import { useEffect } from 'react'

import initializeFaro from '../../config/faroConfig'
import { useEnvironmentContext } from '../EnvironmentProvider'

const FaroMonitoring = ({ children }) => {
  const { grafanaFaroCollectorURL } = useEnvironmentContext()
  useEffect(() => {
    initializeFaro(grafanaFaroCollectorURL)
  }, [])

  return children
}

export default FaroMonitoring
