import { config, library } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faFacebookF, faInstagram, faXTwitter } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'

import React, { createContext } from 'react'

import AdsProvider from '../components/AdsProvider'
import AirshipWebNotifications from '../components/AirshipWebNotifications/AirshipWebNotifications'
import Analytics from '../components/Analytics/Analytics'
import EnvironmentProvider, {
  EnvironmentContextValues,
  loadEnvironmentVariables
} from '../components/EnvironmentProvider'
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary'
import FaroMonitoring from '../components/FaroMonitoring/FaroMonitoring'
import GlobalCSS from '../components/GlobalCSS/GlobalCSS'
import Layout from '../components/Layout'
import { SubdomainContextProvider } from '../components/SubdomainContext/SubdomainContext'
import ThemeProvider from '../components/ThemeProvider'
import ModalManager from '../components/modal/ModalManager'
import ModalProvider from '../components/modal/ModalProvider'
import { logRequestMetaData } from '../components/utilities/log-helper'
import { CookiesKeys } from '../config/cookies'
import regions from '../config/regions'
import { Region as REGION } from '../config/regions'
import { getRegionByGeolocation } from '../core/geolocation/getRegionByGeolocation'
import fetchBreakingNewsBanner from '../data/query/fetchBreakingNewsBanner'
import fetchFooterNY from '../data/query/fetchFooterNY'
import fetchMarketingNavigation from '../data/query/fetchMarketingNavigation'
import fetchNavigation from '../data/query/fetchNavigation'
import fetchNewslettersList, { NewslettersList } from '../data/query/fetchNewslettersList'
import fetchWeatherConditions, { WeatherConditions } from '../data/query/fetchWeatherConditions'
import { HomepageData } from '../data/query/homepage/fetchHomepageData'
import Region from '../data/types/region'
import { validRegion } from '../hooks/useRegion'

import Cookies from 'cookies'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { NextPageContext } from 'next/types'

// TODO: before making this the main redirect handler in crunch, see this story: https://app.shortcut.com/cheddar/story/35913

const useWinston = !process.browser && process.env.NODE_ENV !== 'test'
const logger = useWinston ? require('../utils/winston-logger') : console

config.autoAddCss = false
library.add(fab, faFacebookF, faInstagram, faXTwitter, faEnvelope)

export const SubdomainContext = createContext({
  subdomain: 'default',
  domain: '',
  port: '',
  host: '',
  path: ''
})
const extractSubdomain = async ({ host, path, ctx }: { host: string; path: string; ctx: NextPageContext }) => {
  const { req, res } = ctx
  const [hostname, port] = host.split(':')
  const [subdomain, ...rest] = hostname.split('.')
  const domain = rest.join('.')
  const regionName = validRegion(subdomain) ? subdomain : 'default'
  const region = regions[regionName]

  //Load dynamic env variables for access on the frontend
  const environmentVariables = loadEnvironmentVariables()

  try {
    const navigation = await fetchNavigation((regions as unknown as Region)[regionName].contentfulNavId)
    const footerNY = subdomain === 'newyork' ? await fetchFooterNY() : {}
    const banner = await fetchBreakingNewsBanner(region.contentfulId)
    let homepage: HomepageData | null = null

    const newslettersList = await fetchNewslettersList(regionName)
    const marketingNavigation = await fetchMarketingNavigation(region.contentfulMarketingNavId!)
    const weatherConditions = await fetchWeatherConditions(regionName)

    const surrogateKeys: string = [
      ...(navigation?.entryIDs as []),
      region?.contentfulId,
      region?.contentfulNavId,
      region?.contentfulMarketingNavId,
      banner?.id
    ]
      .filter(Boolean)
      .join(' ')
    res?.setHeader('Surrogate-Key', surrogateKeys)

    const payload = {
      subdomain,
      domain,
      port,
      region: regionName,
      host,
      path,
      navigation,
      banner,
      footerNY,
      homepage,
      newslettersList,
      marketingNavigation,
      weatherConditions,
      environmentVariables
    }

    if (!req || !res) {
      return payload
    }

    const cookies = new Cookies(req, res)

    let regionCookie = cookies.get(CookiesKeys.REGION) ? decodeURI(cookies.get(CookiesKeys.REGION) as string) : null

    if (regionName === REGION.DEFAULT && !regionCookie) {
      const lat = ctx?.req?.headers?.['fastly-geo-lat'] as string
      const long = ctx?.req?.headers?.['fastly-geo-long'] as string
      regionCookie = getRegionByGeolocation({ lat, long }) || REGION.LONG_ISLAND
    }

    if (regionName !== REGION.DEFAULT && regionName !== REGION.NEW_YORK && !regionCookie) {
      const now = new Date()
      const oneMonthFromNow = new Date(now.setMonth(now.getMonth() + 1))
      cookies.set(CookiesKeys.REGION, encodeURI(subdomain), {
        domain,
        expires: oneMonthFromNow,
        httpOnly: false
      })
    }

    if (regionName === REGION.DEFAULT && regionCookie) {
      res.writeHead(302, {
        'Cache-Control': 'private, no-cache, no-store, max-age=0, s-maxage=0',
        Location: `http://${regionCookie}.${host}${path.length > 1 ? path : ''}`
      })
      res.end()
    }

    logRequestMetaData({ subdomain, domain, port, region: regionName, host, path })

    return payload
  } catch (e) {
    if (logger) {
      logger.error(e)
    }

    return {
      subdomain,
      domain,
      port,
      region: regionName,
      host,
      path,
      navigation: null,
      banner: null,
      footerNY: {},
      pageProps: { error: true },
      environmentVariables
    }
  }
}

type CrunchAppProps = {
  domain: string
  err: string
  host: string
  port: string
  subdomain: string
  region: string
  path: string
  navigation: Record<string, unknown>
  banner: Record<string, unknown>
  footerNY: Record<string, unknown>
  homepage: HomepageData
  newslettersList: NewslettersList
  marketingNavigation: Record<string, string>
  weatherConditions: WeatherConditions | null
  environmentVariables: EnvironmentContextValues
}

const CrunchApp = (props: AppProps & CrunchAppProps) => {
  const router = useRouter()

  const {
    Component,
    pageProps,
    domain,
    err,
    host,
    port,
    region,
    subdomain,
    path,
    navigation,
    banner,
    footerNY,
    newslettersList,
    marketingNavigation,
    weatherConditions,
    environmentVariables
  } = props

  return (
    <ErrorBoundary>
      <EnvironmentProvider environmentVariables={environmentVariables}>
        <FaroMonitoring>
          <AirshipWebNotifications region={region} logger={logger} />
          <GlobalCSS />
          <ThemeProvider>
            <SubdomainContext.Provider value={{ subdomain, domain, port, host, path }}>
              <SubdomainContextProvider>
                <AdsProvider>
                  <ModalProvider>
                    <Analytics />
                    <Layout
                      hideLayout={
                        (Component as { showLayout?: boolean }).showLayout === false || router.query.layout === 'false'
                      }
                      navigation={navigation}
                      banner={banner}
                      footerNY={footerNY}
                      newslettersList={newslettersList}
                      marketingNavigation={marketingNavigation}
                      weatherConditions={weatherConditions}
                      regionName={region}>
                      {region === 'newyork' || region !== 'default' ? (
                        <Component {...pageProps} navigation={navigation} newslettersList={newslettersList} err={err} />
                      ) : null}
                    </Layout>
                    <ModalManager />
                  </ModalProvider>
                </AdsProvider>
              </SubdomainContextProvider>
            </SubdomainContext.Provider>
          </ThemeProvider>
        </FaroMonitoring>
      </EnvironmentProvider>
    </ErrorBoundary>
  )
}

CrunchApp.getInitialProps = async ({ ctx }: { ctx: NextPageContext }) => {
  if (ctx?.res?.writableEnded || ctx?.res?.writableEnded === undefined) return {}

  let host =
    (ctx?.req?.headers['x-forwarded-host'] as string)?.replace(/,.*/, '') ||
    ctx?.req?.headers['host'] ||
    document?.location?.host

  let path = ctx?.req?.url || document?.location?.pathname
  host = host.replace(/^www\./, '')

  if (host && host.length > 0) {
    return extractSubdomain({ host, path, ctx })
  } else {
    return {}
  }
}

export default CrunchApp
