const adConfig = (region, rubiconBundleName, logging) => ({
  amazonPubId: 'f80735ed-46e4-4fe1-8bc8-197aef8e44d6',
  unitPath: `/853/${region.adPath}`,
  refreshRate: 30000,
  enableLazyLoad: true,
  lazyLoadConfig: {
    fetchMarginPercent: 0,
    renderMarginPercent: 0,
    mobileScaling: 2.0
  },
  logging,
  universalTargeting: {},
  maxBidMultiplier: 1,
  siteKey: 'news12',
  addSlotLoadTimeout: 100,
  rubiconBundleName,
  openWrapProfileIdDesktop: '5216',
  openWrapProfileIdMobile: '5214'
})

export default function initializeAds(region, rubiconBundleName, logging) {
  if (window.News12Ads) {
    window.News12Ads.initialize(adConfig(region, rubiconBundleName, logging))
  } else {
    console.log('error loading ad bundle')
  }
}
