import {
  CategoryTitle,
  Container,
  DesktopOnly,
  FlexContainer,
  HorizontalCarousel,
  LineSeparator,
  MediaCard,
  MobileOnly,
  Size
} from '@news12/storybook-news'

import { Fragment, useCallback } from 'react'

import { trackGTMEvent } from '../../config/analytics'
import useSlidesToScroll from '../HorizontalCarousel/useSlidesToScroll'
import ThemeProvider from '../ThemeProvider'

import { useTheme } from 'styled-components'

type TopStoriesProps = {
  stories: any[]
}

const TopStories = ({ stories }: TopStoriesProps) => {
  const theme = useTheme()

  const slidesToScroll = useSlidesToScroll()

  const fullWidthStories = stories.slice(0, 3)
  const mobileCarouselStories = stories.slice(3)

  const handleMediaCardClick = useCallback(({ heading }: any, index: number) => {
    trackGTMEvent.gaMediaCardClick({
      cardTitle: heading,
      sectionTitle: 'Top stories',
      cardNumber: index
    })
  }, [])

  return (
    stories.length > 0 && (
      <ThemeProvider theme='dark'>
        <DesktopOnly breakpoint={theme.breakpoints.sm}>
          <HorizontalCarousel options={{ slidesToScroll }}>
            {stories.map((story, index) => (
              <MediaCard
                durationTime={story.durationTime}
                heading={story.heading}
                thumbnail={{
                  alt: story.heading,
                  src: story.thumbnail
                }}
                onClick={() => handleMediaCardClick(story, index)}
                key={story.id}
                href={story.href}
              />
            ))}
          </HorizontalCarousel>
        </DesktopOnly>
        <MobileOnly breakpoint={theme.breakpoints.sm}>
          {fullWidthStories.map(({ durationTime, id, heading, thumbnail, href }) => (
            <Fragment key={id}>
              <LineSeparator margin='1.5rem 1rem' />
              <MediaCard
                fullWidth
                durationTime={durationTime}
                heading={heading}
                thumbnail={{
                  alt: heading,
                  src: thumbnail
                }}
                href={href}
              />
            </Fragment>
          ))}
          {mobileCarouselStories.length > 0 && (
            <>
              <FlexContainer direction='column' padding='1.5rem 1rem' gap='1.5rem'>
                <LineSeparator margin='0' />
                <CategoryTitle uppercased withMarker size={Size.Regular} tag='h2'>
                  Top Stories
                </CategoryTitle>
              </FlexContainer>
              <Container margin='0 0 1.5rem' padding='0'>
                <HorizontalCarousel>
                  {mobileCarouselStories.map(({ durationTime, id, heading, thumbnail, href }) => (
                    <MediaCard
                      durationTime={durationTime}
                      heading={heading}
                      thumbnail={{
                        alt: heading,
                        src: thumbnail
                      }}
                      key={id}
                      href={href}
                    />
                  ))}
                </HorizontalCarousel>
              </Container>
            </>
          )}
        </MobileOnly>
      </ThemeProvider>
    )
  )
}

export default TopStories
