import { Facebook, Instagram, Twitter } from '@news12/storybook-news'

import { FooterField, FooterSectionProps } from '../../../components/FooterSection/FooterSection'

const createFooterOptions = (section: FooterField[]) => {
  return section.map(({ name, path }) => ({
    name,
    href: path,
    id: name
  }))
}

const normalizeFooter = (data: FooterSectionProps['footer']) => {
  return {
    bottomOptions: createFooterOptions(data.about),
    topLists: [
      {
        heading: data.contacts.length ? 'Info' : '',
        options: createFooterOptions(data.contacts)
      },
      {
        heading: 'Follow Us',
        options: [
          {
            name: 'Twitter',
            href: 'https://www.twitter.com/news12/',
            id: 'Twitter',
            icon: <Twitter />
          },
          {
            name: 'Facebook',
            href: 'https://www.facebook.com/News12NewYork/',
            id: 'Facebook',
            icon: <Facebook />
          },
          {
            name: 'Instagram',
            href: 'https://www.instagram.com/news12/',
            id: 'Instagram',
            icon: <Instagram />
          }
        ]
      },
      {
        heading: data.affliates.length ? 'More Resources' : '',
        options: createFooterOptions(data.affliates)
      }
    ]
  }
}

export default normalizeFooter
