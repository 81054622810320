import { TBreakingNewsType } from '@news12/storybook-news'

import fetchContentful from '../../core/fetchContentful'
import { ContentfulLocale } from '../../types'
import { TypeBanner } from '../../types/contentful'
import breakingNewsBannerFieldsSelector from '../selectors/breakingNewsBannerFieldsSelector'

export type BreakingNewsBannerData = {
  title: string
  description: string
  type: TBreakingNewsType
  href: string
  id: string
}

const paramsForBreakingNewsBanner = (regionId: string) => ({
  'fields.region.sys.id': regionId,
  content_type: 'banner',
  include: '2',
  select: breakingNewsBannerFieldsSelector
})

const fetchBreakingNewsBanner = async (regionId: string): Promise<BreakingNewsBannerData> => {
  const bannerData = await fetchContentful<TypeBanner<'WITHOUT_UNRESOLVABLE_LINKS', ContentfulLocale>[]>(
    paramsForBreakingNewsBanner(regionId)
  )

  return {
    id: bannerData?.[0]?.sys?.id,
    title: bannerData?.[0]?.fields?.tagline,
    description: bannerData?.[0]?.fields?.title,
    type: bannerData?.[0]?.fields?.type,
    href: bannerData?.[0]?.fields?.story?.fields?.slug ? `/${bannerData?.[0].fields?.story?.fields?.slug}` : ''
  }
}

export default fetchBreakingNewsBanner
