'use client'

import useRegion from '../hooks/useRegion'

import { usePathname } from 'next/navigation'

export const AD_UNIT_SIZES = {
  maxWidth: { default: '320px', sm: 'none' },
  maxHeight: { default: '50px', sm: 'none' }
}

export const ConfigType = {
  CUSTOM: 'custom',
  FOOTER: 'footer',
  FOOTER_REDESIGN: 'footerRedesign',
  FOOTER_DESKTOP_SPONSORSHIP: 'footerDesktopSponsorship',
  LEADERBOARD: 'leaderboard',
  LEADERBOARD_NEW: 'leaderboardNew',
  LEADERBOARD_REDESIGN: 'leaderboardRedesign',
  LEADERBOARD_DYNAMIC: 'leaderboardDynamic',
  LEADERBOARD_HOMEPAGE_SPONSORSHIP: 'leaderboardHomePageSponsorship',
  STORIES_COLLECTION: 'storiesCollection',
  STORY_PAGE_BYLINE: 'storyPageByline',
  MIDDLEBOARD: 'middleboard',
  MID_CONTENT: 'midContent',
  POSTSCRIPT: 'postscript',
  SIDEBAR: 'sidebar',
  SIDEBAR1: 'sidebar1',
  SIDEBAR2: 'sidebar2',
  HOMEPAGE_SIDEBAR: 'homePageSidebar'
} as const

export type TAdConfigType = (typeof ConfigType)[keyof typeof ConfigType]

export type Tag = {
  name: string
  value: string
}

type BaseTargeting = {
  refresh: string
  template: string
  region: string
  slug: string
  category: string
}

const getSidebarConfig = (type: TAdConfigType, baseTargeting: BaseTargeting) => {
  return {
    targeting: Object.assign({ pos: type }, baseTargeting),
    sizes: [
      [300, 600],
      [300, 250]
    ],
    sizeMappings: [
      {
        threshold: [0, 0],
        sizes: [
          [300, 600],
          [300, 250]
        ]
      }
    ]
  }
}

export default (
  type: TAdConfigType,
  template: string = '',
  tags: Tag[] = [],
  contentfulCategories: string[] = [],
  id: string
) => {
  const region = useRegion()?.shortName as string
  const path = usePathname() ?? ''
  const slug = path.length > 1 ? path.substring(1) : 'home'
  const categoryTag = tags.find(e => e.name === 'categories')
  const category = contentfulCategories ? contentfulCategories.join(',') || '' : categoryTag?.value || ''
  const targetingCategory = category ? category.replace(/ /g, '_') : slug
  const baseTargeting = { refresh: 'true', template, region, slug, category: targetingCategory, slotName: id }

  return {
    [ConfigType.CUSTOM]: {
      targeting: { pos: 'cus', refresh: 'false', category: targetingCategory, template, slotName: id },
      sizes: [[1, 1]],
      sizeMappings: [
        {
          threshold: [0, 0],
          sizes: [[1, 1]]
        }
      ]
    },
    [ConfigType.FOOTER]: {
      targeting: Object.assign({ pos: 'btf' }, baseTargeting),
      sizes: [
        [320, 50],
        [728, 90]
      ],
      sizeMappings: [
        {
          threshold: [0, 0],
          sizes: [[320, 50]]
        },
        {
          threshold: [728, 0],
          sizes: [[728, 90]]
        }
      ]
    },
    [ConfigType.FOOTER_REDESIGN]: {
      targeting: Object.assign({ pos: 'footer' }, baseTargeting),
      sizes: [[320, 50]],
      sizeMappings: [
        {
          threshold: [0, 0],
          sizes: [[320, 50]]
        }
      ]
    },
    [ConfigType.FOOTER_DESKTOP_SPONSORSHIP]: {
      targeting: Object.assign({ pos: 'sticky-footer-desktop' }, baseTargeting),
      sizes: [[728, 90]],
      sizeMappings: [
        {
          threshold: [0, 0],
          sizes: [[728, 90]]
        }
      ]
    },
    /* 'leaderboard' prop is temporarily now,
     it's here just to not break the layouts other than Article.
     sooner or later will be deprecated and
     'leaderboardNew' will be used app wide
    */
    [ConfigType.LEADERBOARD]: {
      targeting: Object.assign({ pos: 'atf' }, baseTargeting),
      sizes: [
        [300, 250],
        [728, 90]
      ],
      sizeMappings: [
        {
          threshold: [0, 0],
          sizes: [[300, 250]]
        },
        {
          threshold: [808, 0],
          sizes: [[728, 90]]
        }
      ]
    },
    [ConfigType.LEADERBOARD_NEW]: {
      targeting: Object.assign({ pos: 'atf' }, baseTargeting),
      sizes: [
        [300, 50],
        [550, 70],
        [728, 90]
      ],
      sizeMappings: [
        {
          threshold: [0, 0],
          sizes: [[300, 50]]
        },
        {
          threshold: [768, 0],
          sizes: [[550, 70]]
        },
        {
          threshold: [808, 0],
          sizes: [[728, 90]]
        }
      ]
    },
    // TODO: remove old leaderboard configs after implementing the redesign for the whole app
    [ConfigType.LEADERBOARD_REDESIGN]: {
      targeting: Object.assign({ pos: 'atf' }, baseTargeting),
      sizes: [
        [320, 50],
        [728, 90],
        [970, 90]
      ],
      sizeMappings: [
        {
          threshold: [0, 0],
          sizes: [[320, 50]]
        },
        {
          threshold: [768, 0],
          sizes: [[728, 90]]
        },
        {
          threshold: [992, 0],
          sizes: [
            [728, 90],
            [970, 90]
          ]
        }
      ]
    },
    [ConfigType.LEADERBOARD_DYNAMIC]: {
      targeting: Object.assign({ pos: 'btf' }, baseTargeting),
      sizes: [
        [300, 250],
        [320, 50],
        [728, 90],
        [970, 90]
      ],
      sizeMappings: [
        {
          threshold: [0, 0],
          sizes: [
            [300, 250],
            [320, 50]
          ]
        },
        {
          threshold: [768, 0],
          sizes: [[728, 90]]
        },
        {
          threshold: [992, 0],
          sizes: [
            [728, 90],
            [970, 90]
          ]
        }
      ]
    },
    [ConfigType.STORIES_COLLECTION]: {
      targeting: Object.assign({ pos: 'btf' }, baseTargeting),
      sizes: [[300, 250]],
      sizeMappings: [
        {
          threshold: [0, 0],
          sizes: [[300, 250]]
        }
      ]
    },
    [ConfigType.STORY_PAGE_BYLINE]: {
      targeting: Object.assign({ pos: 'mid' }, baseTargeting),
      sizes: [
        [300, 50],
        [320, 50],
        [728, 90]
      ],
      sizeMappings: [
        {
          threshold: [0, 0],
          sizes: [
            [300, 50],
            [320, 50]
          ]
        },
        {
          threshold: [1400, 0],
          sizes: [[728, 90]]
        }
      ]
    },
    [ConfigType.MIDDLEBOARD]: {
      targeting: Object.assign({ pos: 'mid' }, baseTargeting),
      sizes: [
        [300, 250],
        [728, 90]
      ],
      sizeMappings: [
        {
          threshold: [0, 0],
          sizes: [[300, 250]]
        },
        {
          threshold: [768, 0],
          sizes: [[728, 90]]
        }
      ]
    },
    [ConfigType.MID_CONTENT]: {
      targeting: Object.assign({ pos: 'mid' }, baseTargeting),
      sizes: [
        [300, 250],
        [728, 90]
      ],
      sizeMappings: [
        {
          threshold: [0, 0],
          sizes: [[300, 250]]
        },
        {
          threshold: [1280, 0],
          sizes: [[728, 90]]
        }
      ]
    },
    [ConfigType.POSTSCRIPT]: {
      targeting: Object.assign({ pos: 'btf2' }, baseTargeting),
      sizes: [[300, 250]],
      sizeMappings: [
        {
          threshold: [0, 0],
          sizes: [[300, 250]]
        }
      ]
    },
    [ConfigType.SIDEBAR]: getSidebarConfig(type, baseTargeting),
    [ConfigType.SIDEBAR1]: getSidebarConfig(type, baseTargeting),
    [ConfigType.SIDEBAR2]: getSidebarConfig(type, baseTargeting),
    [ConfigType.HOMEPAGE_SIDEBAR]: getSidebarConfig(type, baseTargeting),
    [ConfigType.LEADERBOARD_HOMEPAGE_SPONSORSHIP]: {
      targeting: Object.assign({ pos: 'larger-banner-ad' }, baseTargeting),
      sizes: [
        [728, 90],
        [728, 270],
        [970, 90],
        [970, 270]
      ],
      sizeMappings: [
        {
          threshold: [992, 0],
          sizes: [
            [728, 90],
            [728, 270],
            [970, 90],
            [970, 270]
          ]
        }
      ]
    }
  }[type]
}
