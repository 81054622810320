import { ConsoleInstrumentation, Faro, getWebInstrumentations, initializeFaro as initialize } from '@grafana/faro-react'

const initializeFaro: (grafanaFaroCollectorURL: string) => Faro = (grafanaFaroCollectorURL: string): Faro => {
  return initialize({
    url: grafanaFaroCollectorURL,
    app: {
      name: 'crunch',
      version: '1.0.0',
      environment: process.env.NODE_ENV
    },
    sessionTracking: {
      samplingRate: 0.01
    },
    instrumentations: [...getWebInstrumentations(), new ConsoleInstrumentation()]
  })
}

export default initializeFaro
