import React from 'react'

import InternalServerError from '../Error/InternalServerError'

type ErrorBoundaryProps = {
  children: React.ReactNode
}

class ErrorBoundary extends React.Component {
  state: {
    hasError: boolean
  }
  props: ErrorBoundaryProps

  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }
  static getDerivedStateFromError(error) {
    return { hasError: true }
  }
  componentDidCatch(error, errorInfo) {
    console.error({ error, errorInfo })
  }
  render() {
    if (this.state.hasError) {
      return <InternalServerError />
    }

    return this.props.children
  }
}

export default ErrorBoundary
