import { theme } from '@news12/storybook-news'

import useWindowDimension from './useWindowDimension'

import { useTheme } from 'styled-components'

export type Breakpoints = keyof typeof theme.breakpoints

const useBelowBreakpoint = (breakpoint: Breakpoints) => {
  const theme = useTheme()
  const { width } = useWindowDimension()

  const breakpointValue = parseInt(theme.breakpoints[breakpoint])

  return width !== undefined ? width <= breakpointValue : false
}

export default useBelowBreakpoint
